.container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-repeat: no-repeat;
    background-size: contain;
}

.smoke-img-container {
    width: 100vw;
    height: 322.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(10, 152, 49);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 322.5vh;
    z-index: -1;
}

.smoke-bg-container {
    background-repeat: no-repeat;
    background-size: contain;
}

.smoke-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 322.5vh;
    z-index: -1;
}

.title-img-container {
    width: 100vw;
    height: 25vh;
    display: flex;
    justify-content: center;
}

.title-img {
    width: 50vw;
    height: 25vh;
}

.first-content-container {
    width: 100vw;
    height: 112.5vh;
    display: flex;
    justify-content: center;
}

.first-content-left {
    width: 40vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-img {
    width: 35vw;
    height: 85vh;
}

.first-content-right {
    width: 60vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 7rem;
    text-align: center;
}

.first-content-right a {
    text-decoration: none;
}

.custom-button {
    width: 18vw;
    height: 10vh;
    background-color: #d8f726;
    border: none;
    border-radius: 30px;
    font-size: 3rem;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    color: black;
}

.weed-img {
    width: 5vw;
    height: auto;
}

.buffer-zone {
    height: 3vh;
}

.second-content-container {
    width: 100vw;
    height: 65vh;
    display: flex;
    justify-content: center;
}

.second-content-left {
    width: 50vw;
    height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 7rem;
}

.second-content-right {
    width: 50vw;
    height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img1 {
    width: 35vw;
    height: 60vh;
}

.third-content-container {
    width: 100vw;
    height: 40vh;
    display: flex;
    justify-content: end;
}

.blunt-img {
    width: 95vw;
    height: auto;
}

.forth-content-container {
    width: 100vw;
    height: 60vh;
    display: flex;
    justify-content: center;
}

.forth-content-left {
    width: 50vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.forth-content-left-box {
    width: 40vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 4px solid black;
    font-size: 4rem;
    /* border-radius: 30px; */

}

.forth-content-right {
    width: 50vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}

.img2 {
    width: 35vw;
    height: 55vh;
}

.footer {
    width: 100vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bold;
}